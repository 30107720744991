import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import { brainspaceInstance } from "../utils/axiosInstances"; // Make sure to adjust this import to your file structure

export default function UploadDialog({ open, handleClose, setOpen }) {
  const [uploadType, setUploadType] = useState("text");
  const [category, setCategory] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);

  const handleUploadTypeChange = (event) => {
    setUploadType(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (uploadType === "text") {
      try {
        // Replace this console log with your API endpoint call for text upload
        await brainspaceInstance.post(
          "upsert",
          {
            documents: [
              {
                text: text,
                metadata: {
                  source: "file",
                  author: "1",
                  category: category,
                },
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_DATABASE_INTERFACE_BEARER_TOKEN}`,
            },
          }
        );

        console.log("Uploading text:", text, "Category:", category);
      } catch (error) {
        console.log("Error uploading text:", error);
        // You can also inspect the 'error.response' object for more details about the server response
        if (error.response) {
          console.log("Server responded with status:", error.response.status);
          console.log("Response data:", error.response.data);
        }
      }
      setOpen(false);
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "metadata",
        JSON.stringify({
          source: "file",
          author: "1",
          category: category,
        })
      );

      try {
        // Make sure to send `formData` directly, not wrapped in an object.
        await brainspaceInstance.post("upsert-file", formData, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_DATABASE_INTERFACE_BEARER_TOKEN}`,
            "Content-Type": "multipart/form-data", // Important if your server expects multipart form data
          },
        });
      } catch (error) {
        console.log("Error uploading file:", error);
      }

      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Upload Options</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Upload Type</FormLabel>
          <RadioGroup
            aria-label="uploadType"
            name="uploadType"
            value={uploadType}
            onChange={handleUploadTypeChange}
          >
            <FormControlLabel value="text" control={<Radio />} label="Text" />
            <FormControlLabel value="file" control={<Radio />} label="File" />
          </RadioGroup>
        </FormControl>

        <TextField
          margin="dense"
          id="category"
          label="Category"
          type="text"
          fullWidth
          value={category}
          onChange={handleCategoryChange}
        />

        {uploadType === "file" && (
          <div>
            <input type="file" onChange={handleFileChange} />
          </div>
        )}

        {uploadType === "text" && (
          <TextField
            margin="dense"
            id="text"
            label="Your Text"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={text}
            onChange={handleTextChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
