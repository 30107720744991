import React, { useState } from "react";
import TopBar from "./TopBar";
import UploadDialog from "./UploadDialog";

export default function App() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TopBar handleClickOpen={handleClickOpen} />
      <UploadDialog open={open} handleClose={handleClose} setOpen={setOpen} />
      {/* Your other components */}
    </>
  );
}
