// axiosInstance.js
import axios from "axios";

const brainspaceInstance = axios.create({
  baseURL: "https://brainspace-document-api-dev.azurewebsites.net",
  // headers: {
  //   Authorization: `Bearer ${process.env.REACT_APP_DATABASE_INTERFACE_BEARER_TOKEN}`,
  // },
});

const brainspaceMiddleware = axios.create({
  baseURL: process.env.REACT_APP_MIDDLEWARE_URL,
  // headers: {
  //   "x-api-key": process.env.REACT_APP_MIDDLEWARE_API_KEY,
  // },
});

const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  brainspaceMiddleware.interceptors.request.use(async (config) => {
    console.log("Adding access token to middleware request");
    const token = await getAccessTokenSilently();
    console.log(token);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export { brainspaceInstance, brainspaceMiddleware, addAccessTokenInterceptor };
