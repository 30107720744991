import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { useAuthContext } from "../utils/hooks/useAuthContext";

export default function TopBar(props) {
  const thing = useAuthContext();

  console.log(thing);
  return (
    <Box mb={4}>
      <AppBar position="static">
        <Toolbar>
          <Box mr={2}>
            <PsychologyIcon fontSize={"large"} />
          </Box>
          <Typography variant="h6">Brainspace</Typography>
          <Box flexGrow={1} />
          {thing.authenticated && (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() =>
                thing.logout({
                  logoutParams: {
                    returnTo: "http://localhost:3000/",
                  },
                })
              }
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
