import { createContext } from "react";

export const AuthContext = createContext({
  user: null,
  method: "",
  loading: false,
  authenticated: false,
  unauthenticated: false,
  loginWithPopup: () => {},
  loginWithRedirect: () => {},
  logout: () => {},
});
