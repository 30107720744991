// App.js
import { Fragment, useEffect } from "react";
import Chat from "./pages/ChatPage";
import Bar from "./components/Bar";
import LoginPage from "./pages/LoginPage";

// Router
import { Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { AuthConsumer } from "./context/auth0";
import { addAccessTokenInterceptor } from "./utils/axiosInstances";

// AuthGuard
import useAuthGuard from "./utils/useAuthGuard";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  const { loading, authenticated } = useAuthGuard();

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  console.log(loading, authenticated);

  return (
    <Fragment>
      <Bar />
      <Container>
        <AuthConsumer>
          <Routes>
            <Route
              path="/"
              element={
                loading === false && authenticated ? (
                  <Chat />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Add a route for login page */}
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </AuthConsumer>
      </Container>
    </Fragment>
  );
}

export default App;
