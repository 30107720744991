import React from "react";

const LoadingScreen = () => {
  return (
    <div>
      <h4>Loading...</h4>
    </div>
  );
};

export default LoadingScreen;
