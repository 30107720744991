import { useMemo, useCallback, useState } from "react";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import { AuthContext } from "./auth-context";

function AuthProviderWrapper({ children }) {
  const {
    isAuthenticated,
    user,
    isLoading,
    loginWithRedirect,
    loginWithPopup,
    logout,
  } = useAuth0();

  const [popupClick, setPopupClick] = useState(true);

  const handleLoginWithPopup = useCallback(
    async (options) => {
      loginWithPopup(options);
      setPopupClick(false);
    },
    [loginWithPopup]
  );

  const handleLogout = useCallback(
    async (options) => {
      logout(options);
    },
    [logout]
  );

  const checkAuthenticated = isAuthenticated
    ? "authenticated"
    : "unauthenticated";

  const status = popupClick && isLoading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...user,
        displayName: user?.name,
        photoURL: user?.picture,
        role: "admin",
      },
      method: "auth0",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      loginWithRedirect,
      loginWithPopup: handleLoginWithPopup,
      logout: handleLogout,
    }),
    [handleLoginWithPopup, handleLogout, loginWithRedirect, status, user]
  );

  console.log(memoizedValue);

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const AuthProvider = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;

  console.log(domain, clientId, redirectUri);

  const onRedirectCallback = useCallback((appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};
