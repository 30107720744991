import {
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useRef, useState } from "react";
import "./Chat.css";
import { SSE } from "sse";
import SendIcon from "@mui/icons-material/Send";
import { brainspaceMiddleware } from "../../utils/axiosInstances";
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

class ChatMessageDto {
  constructor(role, content) {
    this.role = role;
    this.content = content;
  }
}

export default function Chat(props) {
  const { socket, selectedBrainspace, brainspaceRules } = props;
  let [prompt, setPrompt] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [result, setResult] = useState("");
  const ENTER_KEY_CODE = 13;

  const resultRef = useRef();
  const chatContainer = useRef();
  const scrollBottomRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);

  const scroll = () => {
    const { offsetHeight, scrollHeight, scrollTop } = chatContainer.current;
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      chatContainer.current?.scrollTo(0, scrollHeight);
    }
  };

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  useEffect(() => {
    scroll();
  }, [result]);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleEnterKey = async (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      await sendMessage();
    }
  };

  // useEffect(() => {
  //   if (socket) {
  //     // Listen for new user inputs and add them to the chat
  //     socket.on("user_input", (data) => {
  //       setChatMessages((prevState) => {
  //         return [...prevState, new ChatMessageDto("user", data.text)];
  //       });
  //     });

  //     socket.on("new_message", (data) => {
  //       if (data.text !== undefined) {
  //         resultRef.current = resultRef.current + data.text;
  //         setResult(resultRef.current);
  //       }
  //     });

  //     socket.on("loading_finished", (data) => {
  //       setIsLoading(false);
  //       setChatMessages((prevState) => [
  //         ...prevState,
  //         new ChatMessageDto("assistant", resultRef.current),
  //       ]);
  //       setResult("");
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("new_message");
  //       socket.off("loading_finished");
  //       socket.off("user_input");
  //     }
  //   };
  // }, [socket]); // <--- Make sure to add the socket prop as a dependency

  const sendMessage = async () => {
    // const chunks = [];
    const messages = [];

    if (prompt !== "") {
      setIsLoading(true);
      setChatMessages((prevState) => [
        ...prevState,
        new ChatMessageDto("user", prompt),
      ]);

      // Reset state values
      setPrompt("");
      setResult("");

      // brainspaceRules.map((rule) => {
      //   console.log(rule);
      //   messages.push(rule);
      // });

      // const dbChunks = await brainspaceMiddleware.put(
      //   `workspace/${selectedBrainspace.id}/document`,
      //   {
      //     queryString: prompt,
      //   }
      // );

      // messages.push({
      //   content:
      //     "Utilize the ensuing content from our vector store as relevant contextual reference material only: ",
      //   role: "system",
      // });

      // dbChunks.data.matches.map((message) => {
      //   messages.push({
      //     content: message.text,
      //     role: "system",
      //   });
      // });

      // console.log(`prompt: ${prompt}`);

      messages.push({
        content: `${prompt}`,
        role: "user",
      });

      // dbChunks.data.messages.map((db_result) => {
      //   db_result.messages.map((inner_db_result) =>
      //     // chunks.push(inner_db_result.text)
      //     messages.push({
      //       role: "user",
      //       content: inner_db_result.text,
      //     })
      //   );
      // });

      messages.push({
        role: "user",
        content: prompt,
      });

      console.log(messages);

      const stream = await openai.chat.completions.create({
        model: "gpt-4-0125-preview",
        messages: messages,
        stream: true,
      });

      for await (const chunk of stream) {
        if (chunk.choices[0]?.delta?.content !== undefined) {
          if (chunk.choices[0]?.delta?.content != "\n") {
            resultRef.current =
              resultRef.current + chunk.choices[0]?.delta?.content;
            setResult(resultRef.current);
          } else if (chunk.choices[0]?.delta?.content === " ") {
            resultRef.current = resultRef.current + "\n";
          } else {
            console.log("idk why I was hit");
          }
        }
      }
    } else {
      alert("Please insert a prompt!");
    }
  };

  const listChatMessages = chatMessages.map((chatMessageDto, index) => (
    <ListItem
      key={index}
      sx={{ textAlign: chatMessageDto.role === "user" ? "right" : "left" }}
    >
      <ListItemText primary={`${chatMessageDto.content}`} />
    </ListItem>
  ));

  return (
    <Fragment>
      <Paper elevation={5}>
        <Box p={3}>
          <Grid container spacing={4} alignItems="center">
            <Grid id="chat-window" xs={12} item>
              <List
                id="chat-window-messages"
                style={{ whiteSpace: "pre-wrap" }}
                ref={chatContainer}
              >
                {listChatMessages}
                <ListItem key={123811290381038109381209381}>
                  <ListItemText primary={result} />
                </ListItem>

                <ListItem ref={scrollBottomRef}></ListItem>
              </List>
            </Grid>
            <Grid xs={11} item>
              <FormControl fullWidth>
                <TextField
                  onChange={handlePromptChange}
                  onKeyDown={handleEnterKey}
                  value={prompt}
                  label="Type your message..."
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid xs={1} item>
              <IconButton
                onClick={sendMessage}
                aria-label="send"
                color="primary"
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
}
