import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../utils/hooks/useAuthContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { authenticated, loginWithPopup } = useAuthContext();

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated, navigate]);

  return (
    <div>
      <button onClick={() => loginWithPopup()}>Log In</button>{" "}
    </div>
  );
};

export default LoginPage;
