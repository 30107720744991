import React, { Fragment, useState } from "react";
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { brainspaceMiddleware } from "../utils/axiosInstances";

const CategorySelector = (props) => {
  const {
    brainspaces,
    selectedBrainspace,
    newBrainspace,
    setBrainspaces,
    setSelectedBrainspace,
    setNewBrainspace,
  } = props;
  const [open, setOpen] = useState(false);

  const handleSelectChange = async (event) => {
    const value = event.target.value;

    if (value === "add") {
      setOpen(true);
    } else {
      const { data } = await brainspaceMiddleware.get(`/workspace/${value}/`);
      setSelectedBrainspace(data);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCategory = async () => {
    if (newBrainspace) {
      setBrainspaces([...brainspaces, newBrainspace]);
      setNewBrainspace("");
      setOpen(false);
    }
  };

  return (
    <Fragment>
      {brainspaces !== null && (
        <Paper elevation={5} style={{ padding: "24px" }}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="category-label">Brainspace</InputLabel>
                <Select
                  labelId="category-label"
                  value={selectedBrainspace.name || ""}
                  onChange={handleSelectChange}
                >
                  {brainspaces.map((brainspace, index) => {
                    return (
                      <MenuItem key={index} value={brainspace.id}>
                        {brainspace.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value="add">
                    <AddIcon /> Add more
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Category</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Category Name"
                fullWidth
                value={newBrainspace}
                onChange={(e) => setNewBrainspace(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddCategory} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </Fragment>
  );
};

export default CategorySelector;
