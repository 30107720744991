import React, { Fragment, useEffect, useState } from "react";

import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
// const URL =
//   process.env.NODE_ENV === "production" ? undefined : "http://localhost:3005";

// MUI
import { Grid } from "@mui/material";

// COMPONENTS
import Chat from "../components/chat/Chat";
import RuleDialog from "../components/RuleDialog";
import IntentBar from "../components/intent/IntentBar";
import { brainspaceMiddleware } from "../utils/axiosInstances";
import CategorySelector from "../components/BrainspaceSelector";

// const socket = io(process.env.REACT_APP_MIDDLEWARE_SOCKET_ENDPOINT, {
//   extraHeaders: {
//     "x-api-key": process.env.REACT_APP_MIDDLEWARE_API_KEY,
//   },
// });

const socket = "";
const ChatPage = () => {
  let ruleSystemMessage = [];
  const [brainspace, setBrainspace] = useState(null);
  const [intent, setIntent] = useState(null);
  const [rules, setRules] = useState([]);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  // Brainspace selection
  const [brainspaces, setBrainspaces] = useState(null);
  const [selectedBrainspace, setSelectedBrainspace] = useState(null);
  const [newBrainspace, setNewBrainspace] = useState("");

  useEffect(() => {
    const getAllWorkspaces = async () => {
      const { data } = await brainspaceMiddleware.get(`workspace/`);
      setBrainspaces(data);
    };
    getAllWorkspaces();
  }, []);

  useEffect(() => {
    const getWorkspace = async () => {
      if (selectedBrainspace === null) return;
      console.log(selectedBrainspace);
      const { data } = await brainspaceMiddleware.get(
        `workspace/${selectedBrainspace}/`
      );

      if (data.rules && data.rules.length > 0) {
        setRules(data.rules);
      }

      if (data.intent && data.intent !== "") {
        setIntent(data.intent);
      }
    };
    getWorkspace();
  }, [selectedBrainspace]);

  ruleSystemMessage = rules?.map((rule) => ({
    role: "system",
    content: `This is a strict rule you must follow when responding: ${rule.ruletext}`,
  }));

  return (
    <Grid container spacing={4} alignItems="stretch">
      {selectedBrainspace === null ? (
        <div>
          {brainspaces !== null && (
            <div>
              {brainspaces.map((space) => (
                <button
                  onClick={() => {
                    setSelectedBrainspace(space.id);
                  }}
                >
                  {space.name}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Fragment>
          {/* <Grid item xs={12}>
            <IntentBar
              intent={intent}
              setIntent={setIntent}
              selectedBrainspace={selectedBrainspace}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Chat
              selectedBrainspace={selectedBrainspace}
              brainspaceRules={[
                ...ruleSystemMessage,
                {
                  role: "system",
                  content: `This is either the intent, goal or reason I am asking the following question. I want you to use this to help guide your response with the context of my ultimate overarching goal for all responses: ${intent}`,
                },
              ]}
            />
          </Grid>
          {/* <Grid item xs={4}>
            {brainspaces && (
              <RuleDialog
                rules={rules}
                setRules={setRules}
                selectedBrainspace={selectedBrainspace}
              />
            )}
          </Grid> */}
        </Fragment>
      )}
    </Grid>
  );
};

export default ChatPage;
