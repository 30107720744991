import { useAuthContext } from "./hooks/useAuthContext";

const useAuthGuard = () => {
  const { authenticated, loading } = useAuthContext();

  // Return both isLoading and isAuthenticated
  return { loading, authenticated };
};

export default useAuthGuard;
